.admin-information_container {
    display: flex;
    /*align-items: center;*/
    margin: auto;
    background-color: #f6f7f9;
    /*padding: 0 0 20px 0;*/
    border-radius: 5px;
    width: 100%;
    position: relative;
}

.admin-information_navigation {
    /*padding: 0 20px;*/
}

.flex-column {
    display: flex;
    flex-direction: column;
}
.admin-information_subcontainer {
    max-height: 224px;
    width: 100%;
    /*padding-top: 20px;*/
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #1e1d19;
    border-radius: 0 0 5px 0;
    color: white;
}



.admin-information_details {
    width: 170px;
    /*border: 1px solid #c0c0c0;*/
    padding: 20px;
}

.admin-information_graphs {
    margin: 20px 0;
    width: 90%;
    height: 300px;
    /*border: none;*/
    border-radius: 5px;
}

.graph-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.admin-information_logs {
    width: 100%;
    height: 400px;
    /*border: 1px solid #c0c0c0;*/
    padding: 20px;
    /*border-radius: 5px;*/
    /*background-color: #eeeeee;*/
    /*overflow: scroll;*/
}

.admin-information_logs table {
    width: 100%;
}

.admin-information_navigation .nav-plate {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 20px;
    border-radius: 5px;
    background-color: #eeeeee;
    margin: 20px 0;
    cursor: pointer;
}

.mini-graph {
    width: 100%;
}
.statistic-graph {
    width: 100%;
}

.admin-information_customer-list {
    width: 100%;
    /*margin: 20px 0;*/
}


.customer-usage_summary-admin {
    padding: 0 35px;
    margin: 20px 0 0px !important;
}

.admin-timeframe {
    /*display: flex;*/
    /*flex-direction: row-reverse;*/
    /*align-items: center;*/
}
.admin-timeframe p {
    /*margin: 0;*/
    /*padding-left: 10px;*/
}

.graph-style_container {
    display: flex;
}

.timeframe-plate_admin {
    margin: 30px !important;
}

.graph-style_btn {
    width: 100px;
    height: 50px;
    background: #61dafb;
}

.user-information_cell-selection {
    padding-left: 5%;
}

.customer-usage_summary-admin .customer-usage_summary-plate {
    /*width: 100%;*/
}


@media (max-width: 600px) {
    .admin-information_container {
        flex-direction: column;
        padding: 0;
    }
    .admin-information_subcontainer {
        flex-direction: column;
    }
    /*.admin-information_details {*/
    /*    width: 100%;*/
    /*}*/
    .admin-information_graphs {
        padding: 0;
    }

    .timeframe-plate_admin {
        margin: 20px !important;
        width: 90% !important;
    }
}
