.user-information_container {
    display: flex;
    align-items: center;
}

.user-image {
    width: 150px;
    height: 150px;
    align-self: flex-start;
}
.user-information {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    width: 100%;
}

.user-information_cell-row {
    display: flex;
    flex-direction: row !important;
}

.user-information_cell-row-inner {
    padding-right: 20px;
}

.user-information_not-rewritable {
    background-color: white;
    padding: 5px;
    width: 100%;
    min-height: 30px;
}
.user-information input {
    border-radius: 2px;
    border: none;
    outline:none;
    outline-offset: 0;
    background-color: white;
    padding: 5px;
    width: 100%;
}
.user-information input::placeholder {
    color: #c0c0c0;
}

.user-information_cell {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    min-height: 30px;
}
.user-information_container label {
    margin-bottom: 0;
}
.term {
    color: #c0c0c0;
    font-size: 12px;
}


.user-information_table-container {
    display: flex;
}

.user-information .MuiTableCell-body {
    padding: 0;
}

@media (max-width: 600px) {
    .user-information_container {
        flex-direction: column;
    }
    .user-information {
        margin-left: 0;
    }
    .user-image {
        align-self: center;
    }
}