.help-container_text {
    margin-bottom: 40px;
}

.help-container ul{
    margin-bottom: 40px;
    background-color: white;
    border-radius: 5px;
    padding: 15px 30px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%);
}
.help-container h6 {
    margin-bottom: 40px;
    font-size: 18px;
}
.help-container li {
    margin-bottom: 20px;
}

.help-page_plates-container {
    display: flex;
    justify-content: space-between;
}

.help-page_plate {
    background-color: #1e1d19;
    border-radius: 5px;
    padding: 10px;
    color: white;
    width: 39%;
    overflow: hidden;
}
.help-page_plate b {
    display: block;
    color: #61dafb;
    margin-bottom: 20px;
}
.help-page_plate p {
    height: 100px;
}
.help-page_plate:nth-child(1) {
    width: 18%;
}


@media (max-width: 1000px) {
    .help-page_plate p {
        font-size: 11px;
    }
    .help-page_plate:nth-child(1) {
        width: 12%;
    }
    .help-page_plate {
        width: 42%;
        padding: 5px;
    }
}

