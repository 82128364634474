.customer-page_container {
    background-color: #f6f7f9;
    padding: 50px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: 100%;
    /*height: 100vh;*/
    /*margin: auto;*/
    position: relative;
}

.customer-page_container h4 {
    margin-bottom: 30px;
}


.customer-page_flex {
    display: flex;
}

@media (max-width: 600px) {
    .customer-page_flex {
        flex-direction: column;
    }
    .customer-page_container {
        padding: 50px 10px;
    }
    .customer-page_container h4 {
        text-align: center;
    }
    .customer-page_container .login-page_logo {
        display: none;
    }
}


