.app-container {
    /*padding: 100px 0;*/
    min-height: 100vh;
    background-color: #1e1d19;
    /*background: linear-gradient(180deg, rgba(86,94,217,1) 0%, rgba(255,255,255,1) 0%, rgba(187,196,255,1) 100%);*/
}

.flex-row {
    display: flex;
}
.full-width {
    width: 100%;
}

.active {
    background-color: #61dafb !important;
    /*background-color: #e2dc46 !important;*/
    /*background-color: #1e1d19 !important;*/
    /*color: white !important;*/
    color: black !important;
}