.contacts-container {
    display: flex;
    /*height: 100vh;*/
}

.contacts-table_container {
    display: flex;
    /*align-items: center;*/
    width: 100%;
}

.contacts-table_container .MuiPaper-root {
    width: 100%;
}

.pass {
    color: red;
}

/**, ::after, ::before {*/
/*    border: none !important;*/
/*}*/

/*.MuiInputBase-input:nth-child(2) {*/
/*    color: red !important;*/
/*}*/

@media (max-width: 600px) {
    .contacts-container {
        flex-direction: column;
    }
}