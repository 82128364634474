.required input::placeholder {
    color: #ffb0b2;
}
.css-yk16xz-control {
    border: none !important;
}

.css-1wa3eu0-placeholder {
    color: #c0c0c0 !important;
}


.radio {
    ddisplay: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 140px;
}
.radio p {
    margin: 0;
    width: 250px;
}
