.test-navigation_container {
    /*position: absolute;*/
    /*top: 1%;*/
    /*left: 1%;*/
    height: 100%;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    /*padding: 10px;*/
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: white;
}
.header-logo {
    width: 100px;
    height: 50px;
    margin: 20px 0;
}


.test-navigation_container a {
    /*color: black; */
    color: white;
    font-size: 20px;
    padding: 5px 20px;
    margin: 5px 20px 5px 0;
    border-radius: 0 20px 20px 0;
}
.nav-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1e1d19;
    position: relative;
    padding: 20px 0 40px;
    margin-bottom: 50px;
}
.nav-header_shift {
    text-align: center;
}
.nav-header_shift p {
    color: white;
}
.nav-name {
    min-height: 24px;
}
.nav-header_shift-subtitle {
    opacity: 0.5;
    font-size: 12px;
}
.test-navigation_container span {
    text-transform: uppercase;
    text-align: center;
    color: white;
    opacity: 0.5;
    margin: 20px 0;
}
.nav-photo {
    top: 185px;
    left: 50%;
    width: 80px;
    height: 80px;
    padding: 8px;
    position: absolute;
    transform: translateX(-50%);
    background: white;
    box-sizing: content-box;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}

.nav-photo img {
    color: transparent;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: center;
    border-radius: 50%;
}


.test-navigation_container-inner {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    height: 100%;
    margin-top: 20px;
    padding-top: 20px;
    /*background-color: #f6f7f9;*/
    background-color: #1e1d19;
}

.test-navigation_container-inner a:hover {
    text-underline: none !important;
    text-decoration-color: transparent !important;
}