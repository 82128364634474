.login-page_container {
    background-color: white;
    padding: 20px 50px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: 400px;
    margin: auto;
    position: relative;
}
.login-page_container-outer {
    padding: 40px 0;
}

.login-page_logo {
    width: 80px;
    height: 30px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.login-page_container h1 {
    color: #202020;
    text-align: center;
    font-weight: bolder;
    font-size: 35px;
    margin: 20px 0;
}
.login-page_container form {
    display: flex;
    flex-direction: column;
}
.login-page_container input {
    width: 100%;
    border-radius: 2px;
    border: none;
    padding: 10px;
    background-color: #eeeeee;
    outline:none;
    outline-offset: 0;
}

.label-checkbox {
    display: flex;
    color: #c0c0c0;
    align-items: center;
}
.label-checkbox p {
    margin: 0 20px;
}
.label-checkbox input {
    width: auto;
    cursor: pointer;
}
.login-page_submit p {
    margin: 0;
}
.login-page_submit {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 10px;
    cursor: pointer;
    margin-top: 40px;
    background-color: #61dafb !important;
}
.login-page_subtext {
    color: #c0c0c0;
    text-align: center;
    margin: 40px 0 20px;
}

.login-page_social-networks {
    display: flex;
    justify-content: space-between;
}

.login-page_facebook {
    width: 48%;
    height: 30px;
    border: 1px solid #c0c0c0;
    background: url("../../Facebook-Logo.wine.svg") no-repeat center / 50%;
    cursor: pointer;
    border-radius: 2px;
}
.login-page_google {
    width: 48%;
    height: 30px;
    border: 1px solid #c0c0c0;
    background: url("../../Google-Logo.wine.svg") no-repeat center / 50%;
    cursor: pointer;
    border-radius: 2px;
}

.login-page_signup {
    color: #c0c0c0;
    text-align: center;
    margin: 40px 0 0;
    font-size: 16px;
}
.login-page_signup a {
    text-decoration: underline !important;
    cursor: pointer;
    font-size: 16px;
}

.version-class {
    margin-top: 20px;
    color: #c0c0c0;
    text-align: center;
    font-size: 12px;
}



@media (max-width: 400px) {
    .login-page_container {
        width: 80%;
        padding: 20px 30px;
        margin-top: 40px;
    }
}
