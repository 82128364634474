.customer-list_container {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-around;*/
    /*border: 1px solid #c0c0c0;*/
    /*border-radius: 5px;*/
    padding-top: 10px;
    background-color: #1e1d19;
    height: 224px;
    overflow: scroll;
    width: 100%;
}

.customer-list_plate {
    /*border: 1px solid #c0c0c0;*/
    margin: 5px;
    display: flex;
    flex-direction: column;
    width: 22%;
    max-height: 100px;
    padding: 8px;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.customer-list_plate-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.customer-list_plate-subcontainer p {
    max-width: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.customer-list_plate span {
    color: #c0c0c0;
    font-size: 8px;
}
.customer-list_plate p {
    margin: 0;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
}
.admin-information_details p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customer-list_plate h5 {
    margin: 0;
}

.customer-list_plate .customer-list_plate-btn {
    border: none;
    font-size: 10px;
    margin-top: 15px;
    border-radius: 5px;
    padding: 5px;
    color: black;
    background-color: #61dafb;
    /*background-color: #e2dc46;*/

    outline: none;
}

.customer-list_outer {
    display: flex;
}


.customer-list_plate2 {
    background-color: yellow;
}
.customer-list_plate3 {
    background-color: red;
}
.customer-list_plate1 {
    background-color: gray;
}

@media (max-width: 600px) {
    /*.customer-list_container {*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*}*/
    .admin-information_details {
        display: none;
    }
}
