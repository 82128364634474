.customer-usage_container {
    display: flex;
}

.customer-usage_subcontainer {
    width: 100%;
    padding: 50px 15px 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: rgb(246 247 249);
}

.traffic-plate_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.traffic-plate {
    width: 100%;
    border-radius: 5px;
    background-color: #141619;
    color: white;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.user-information_cell-customer-selection {
    box-shadow: 0 2px 1px -1px rgba(0 0 0 / 40%);
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    margin-bottom: 20px;
}

.traffic-plate_num {
    font-size: 30px;
}

.customer-usage_balance {
    width: 30%;
    padding: 15px;
    background-color: white;
    margin: 20px 0;
    border-radius: 5px;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.customer-usage_balance p {
    font-size: 30px;
}

.customer-usage_balance-btn {
    background-color: #61dafb;
    /*color: white;*/
    border: none;
    border-radius: 5px;
    padding: 10px;
}

.customer-usage_summary {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 20px;
}
.customer-usage_summary-plate {
    width: 49%;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.timeframe-plate {
    display: flex;
    justify-content: space-between;
}
.timeframe-plate button {
    background-color: #61dafb;
    /*color: white;*/
    border: none;
    border-radius: 5px;
    padding: 10px;
}

.customer-usage_graph {
    height: 250px;
    width: 90%;
    border: none;
    border-radius: 5px;
}

.mini-graph {
    height: 250px;
    border: none;
    border-radius: 5px;
}

.disabled {
    color: darkgrey;
}


@media (max-width: 600px) {
    .customer-usage_container {
        flex-direction: column;
    }
    .customer-usage_summary {
        flex-direction: column;
    }
    /*.traffic-plate_container {*/
    /*    flex-direction: column;*/
    /*}*/
    .traffic-plate {
        width: 100%;
        margin-bottom: 20px;
    }
    .customer-usage_summary-plate {
        width: 100%;
        margin-bottom: 20px;
    }
}

@media (min-width: 1500px) {
    /*.traffic-plate_container {*/
    /*    flex-direction: row;*/
    /*}*/
    .traffic-plate {
        margin-right: 20px;
    }
}